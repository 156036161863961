/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import 'bootstrap';
// // loads jQuery and Axios into the window object
import './bootstrap';

import 'multiple-select';

// International Telephone Input plugin
import 'intl-tel-input/build/js/intlTelInput-jquery';
import 'intl-tel-input/build/js/utils';

// polyfill for IE
import 'formdata-polyfill';

// $.LoadingOverlay(...)
import 'gasparesganga-jquery-loading-overlay';

import 'jquery-validation';

import 'typeahead.js';
import { startBugsnag } from './bugsnag';

import toastr from 'toastr';

const $ = window.jQuery;

window.toastr = toastr;

startBugsnag();

// Functions for showing/hiding spinner icons on buttons for visual feedback to user that something is processing
var spinnerButtonOriginalHTML = {};

window.showSpinner = function showSpinner(button) {
    $(button).prop('disabled', true);
    spinnerButtonOriginalHTML[button] = $(button).html();

    // .width() rounds to nearest integer. getBoundingClientRect() allows for fractional width
    var height = $(button)[0].getBoundingClientRect().height;
    var width = $(button)[0].getBoundingClientRect().width;

    $(button).html('<i class="fas fa-spinner fa-pulse" style="letter-spacing: normal"></i>');

    // Set the height/width using the same method as retrieving the height/width
    $(button).css('height', height + 'px');
    $(button).css('width', width + 'px');
};

window.hideSpinner = function hideSpinner(button) {
    $(button).prop('disabled', false);
    $(button).html(spinnerButtonOriginalHTML[button]);
};
// if ScriptRegistry is defined, register this script
window.ScriptRegistry?.register(window.ScriptRegistry.knownScripts.app);